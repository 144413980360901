<template>
    <div class="index">
        <div class="service">
            <div class="titleContent">
                <p class="p1">曼扉心理，企业心理服务</p>
                <span class="span1">Enterprise psychology service</span>
            </div>
            <p class="p3">
                不同于员工帮助计划(EAP Employee Assistance
                Program)，曼扉心理将企业心理服务与医疗健康管理紧密结合，更有效率地解决员工的个人心理困扰，提升员工的心理资本和幸福感，完善组织管理，帮助员工更好地工作与生活，建构一个更健康的工作团队，全方位地提高员工绩效和企业效益。


            </p>
            <p class="p3">
                相较于各类心理咨询机构提供的 EAP
                服务，曼扉心理的核心优势在于其专业的医疗与心理服务背景，出自四川大学华西医院的青年专家团队提供优质的医疗咨询和出自成都各大高校的专业心理教师提供高校的心理服务以及社会工作团队，创造了一站式的企业员工心理健康服务，满足企业心理服务中的各种实际需求。
            </p>
            <p class="p3">一站式的企业员工心理健康服务，是一种中长期心理服务，包含企业心理健康体系的建立，企业心理健康环境优化（员工心理评估、员工健康心理热线、心理健康讲座（沙龙）辅导、心理咨询、医疗绿色通道等），内部
                EAP
                人才培养，危机干预体系扶持，效果评估与交流推广。针对一些企业的短期需求，可根据企业的需求选择定制短期的企业心理服务，包含员工心理评、心理健康讲座（沙龙）辅导、个别咨询。</p>
        </div>
        <div class="programme">
            <div class="titleContent">
                <p class="p1">曼扉心理，服务方案</p>
                <span class="span1">Service scheme</span>
            </div>
            <div class="programmeContent">
                <div class="item" @click="toProgramme(item.id)" v-for="item in programmeList" :key="item.id">
                    <p class="p2"><span></span> {{ item.name }}</p>
                    <div class="bottomStyle"></div>
                </div>
                <!-- <div class="item">
                    <p class="p2"><span></span> 中长期企业心理服务</p>
                    <div class="bottomStyle"></div>
                </div>
                <div class="item">
                    <p class="p2"><span></span> 特色企业心理服务</p>
                    <div class="bottomStyle"></div>
                </div> -->
            </div>
        </div>
        <div class="case1">
            <div class="titleContent">
                <p class="p1">曼扉心理，服务案例</p>
                <span class="span1">Service case</span>
            </div>
            <div :class="$store.state.isPhone  ? 'caseContent phone' : 'caseContent'">
                <div class="left" @click="checkIndex('decrease')">
                    <img src="../../assets/images/enterprise/left.png" alt="">
                </div>
                <div class="right" @click="checkIndex('add')">
                    <img src="../../assets/images/enterprise/left.png" alt="">
                </div>
                <div class="item" v-for="item in caseList.slice(nowIndex, 5)" :key="item.id">
                    <img :src="require('../../assets/images/enterprise/' + item.imgPath)" alt="">
                    <el-tooltip v-if="item.propContent" placement="bottom" effect="light" class="text">
                        <div slot="content" style="width: 100%; margin-left: 10px; box-sizing: border-box;"
                            v-html="item.propContent">
                            <!-- 德仁堂教育集团旗下新都东骏蒙特梭利幼儿园的心理服务，是省内仅有的进驻幼儿园的心理服务机构。
                            <br />开展的服务包括熟悉幼儿园工作流程。参与每一个班级的教学活动。认识每一个班级的教师。<br />
                            开展一次涉及全园工作人员的心理测评。搜集员工相关资料，建立员工心理档案。根据测评开展员工心理问题的辅导，填写辅导记录表。
                            <br />
                            摸底幼儿园员工心理培训需求，设计员工心理健康教育讲座课程。整理健全幼儿园心理危机服务预案。<br />
                            针对家长开展心理科普教育活动。建设幼儿园心理服务相应宣传渠道，如公众号等。 -->
                        </div>
                        <div class="text">
                            <p class="p3">{{ item.name }}</p>
                            <span class="span2">{{ item.text }}</span>

                        </div>
                    </el-tooltip>
                    <div class="text" v-else>
                        <p class="p3">{{ item.name }}</p>
                        <span class="span2">{{ item.text }}</span>

                    </div>
                </div>
                <!-- <el-carousel :interval="5000" arrow="always">
                        <el-carousel-item v-for="item in caseList" :key="item.id">
                            <h3>{{ item.name }}</h3>
                        </el-carousel-item>
                    </el-carousel> -->
            </div>
        </div>
    </div>
</template>

<script>
export default {
    data() {
        return {
            isPhone: false,
            programmeList: [
                { id: 1, name: '短期企业心理服务' },
                { id: 2, name: '中长期企业心理服务' },
                { id: 3, name: '特色企业心理服务' },
            ],
            caseList: [
                { id: 1, imgPath: '编组 54.jpg', name: '空军讲座', text: '曼扉心理专家受邀为空军某部官兵就心理危机干预开展为期两天的培训项目。' },
                { id: 2, imgPath: '编组 55.jpg', name: '西藏评估', text: '曼扉心理承接西藏某单位民警心理测评项目，通过线上评估手段，快速高效地在4天内完成近3000名员工的心理体检。  ', propContent: '曼扉心理承接西藏某单位民警心理测评项目，通过线上评估手段，<br/>快速高效地在4天内完成近3000名员工的心理体检。' },
                { id: 3, imgPath: '编组 56.jpg', name: '某医院心理体检创新', text: '曼扉心理结合反馈的植物神经功能评估系统及心理测评，为某医院健康管理中心创新性地开展心理体检项目，被检者五分钟内完成心理体检工作，获得良好地体检体验。 ', propContent: '曼扉心理结合反馈的植物神经功能评估系统及心理测评，<br/>为某医院健康管理中心创新性地开展心理体检项目，<br/>被检者五分钟内完成心理体检工作，获得良好地体检体验。' },
                { id: 4, imgPath: '编组 57.jpg', name: '新都某幼儿园的心理服务', text: '开展的服务包括熟悉幼儿园工作流程。参与每一个班级的教学活动。认识每一个班级的教师。开展一次涉及全园工作人员的心理测评。搜集员工相关资料，建立员工心理档案。根据测评开展员工心理问题的辅导，填写辅导记录表。摸底幼儿园员工心理培训需求，设计员工心理健康教育讲座课程。整理健全幼儿园心理危机服务预案。针对家长开展心理科普教育活动。建设幼儿园心理服务相应宣传渠道，如公众号等。 ', propContent: '开展的服务包括熟悉幼儿园工作流程。参与每一个班级的教学活动。<br/>认识每一个班级的教师。开展一次涉及全园工作人员的心理测评。<br/>搜集员工相关资料，建立员工心理档案。<br/>根据测评开展员工心理问题的辅导，填写辅导记录表。<br/>摸底幼儿园员工心理培训需求，设计员工心理健康教育讲座课程。<br/>整理健全幼儿园心理危机服务预案。针对家长开展心理科普教育活动。<br/>建设幼儿园心理服务相应宣传渠道，如公众号等。' },
                { id: 5, imgPath: '编组 58.jpg', name: '戒烟项目', text: '曼扉心理专家受邀在某单位开展戒烟项目，结合医学咨询和戒烟团体、线下支持，戒烟员工很好地度过了尼古丁机型戒断期，半年戒烟率高达60%以上。 ', propContent: '曼扉心理专家受邀在某单位开展戒烟项目，结合医学咨询和戒烟团体、线下支持，<br/>戒烟员工很好地度过了尼古丁机型戒断期，半年戒烟率高达60%以上。' },
            ],
            nowIndex: 0,
        }
    },
    methods: {
        // 跳转到服务方案
        toProgramme(val) {
            this.$router.push({
                path: '/enterprise/programme',
                query: {
                    id: val
                }
            })
        },
        checkIndex(val) {
            // console.log(this.nowIndex)

            if (this.$store.state.isPhone ) {
                // console.log('手机端')

                if (val === 'add' && this.nowIndex < 4) {
                    this.nowIndex++;

                } else if (val === 'decrease' && this.nowIndex > 0) {
                    this.nowIndex--;
                }
            } else {
                // console.log('PC端')
                if (val === 'add' && this.nowIndex < 2) {
                    this.nowIndex++;

                } else if (val === 'decrease' && this.nowIndex > 0) {
                    this.nowIndex--;
                }
            }



        }
    },
    created() {
        // if ((navigator.userAgent.match(/(Android)/i))) {
        //     this.isPhone = true;
        //     console.log(this.isPhone)
        // }
        
    }
}
</script>

<style lang="scss" scoped>
.index {
    .service {

        // text-align: center;
        .p3 {
            // font-weight: 500;
            color: var(--memoFont-color);
            line-height: 22px;
            margin-bottom: 20px;
        }
    }

    .programme {
        .programmeContent {
            display: flex;
            flex-wrap: wrap;
            justify-content: space-around;

            .item {
                // display: inline-block;
                position: relative;
                width: 352px;
                height: 90px;
                border: 1px solid #D8D8D8;
                border-left: none;
                border-right: none;

                &:hover {
                    cursor: pointer;

                    .bottomStyle {
                        display: block;
                    }

                    p {
                        text-decoration: underline;
                        color: #D1B458;
                    }
                }

                p {
                    text-align: center;
                    line-height: 90px;
                    color: var(--memoFont-color);
                }

                span {
                    display: inline-block;
                    width: 12px;
                    height: 12px;
                    background: #D1B458;
                    margin-right: 5px;
                }
            }
        }
    }

    .case1 {

        // .phone.caseContent{
        //     .item
        // }
        .caseContent {
            width: 100%;
            height: 330px;
            overflow: hidden;
            display: flex;
            flex-wrap: wrap;
            // overflow-x: scroll;
            margin: 0;
            padding: 0;
            position: relative;

            .left,
            .right {
                position: absolute;
                top: 60px;

                img {
                    width: 66px;
                    cursor: pointer;
                }
            }

            .left {
                left: 20px;
            }

            .right {
                right: 45px;

                img {
                    transform: rotate(180deg);
                }
            }

            .item {
                width: 31%;
                margin: 0px 7px;
                margin-bottom: 80px;

                // float: left;
                // width: 350px;
                // transform: translateX(-250px);
                // margin-right: 18px;
                // &:nth-child(3){
                //     margin-right: 0px;
                // }
                &:hover {
                    .text {
                        box-shadow: 0px 2px 4px 0px rgba(0, 0, 0, 0.25);
                    }
                }

                img {
                    width: 100%;
                    // height: 188px;
                    // width: 350px;
                    border: none;

                }

                .text {
                    // background: #000;
                    border: 1px solid #DDDDDD;
                    border-top: none;
                    padding: 9px 17px;
                    height: 120px;

                    p {
                        color: #303030;
                        font-weight: 600;
                        line-height: 20px;
                        margin-bottom: 5px;
                    }

                    span {
                        color: var(--memoFont-color);
                        line-height: 22px;
                        text-overflow: -o-ellipsis-lastline;
                        overflow: hidden; //溢出内容隐藏
                        text-overflow: ellipsis; //文本溢出部分用省略号表示
                        display: -webkit-box; //特别显示模式
                        -webkit-line-clamp: 4; //行数
                        line-clamp: 4;
                        -webkit-box-orient: vertical;
                    }
                }
            }
        }
    }
}

@media screen and (max-width: 750PX) {
    .index .case1 {
        .caseContent {

            // .right{
            //     right:20px;
            // }
            .item {
                width: 100%;
            }
            .right{
                right:20px;
            }
        }
    }

}</style>